import { defineStore } from "pinia";
import { useMarkerStore } from "./marker";
import event from "../events/eventBus";
import _ from "lodash";

const defaultState = {
    cameraViews: ["top", "front"],
    activeCameraView: 0,
    cameraZoom: 5,
    cameraRotate: 50,
    focusMode: false,
    cameraFocusZoom: 5,
    cameraFocusRotate: 50,
    cameraLoop: undefined,
};

export const useCameraStore = defineStore('camera', {
    state: () => ({...defaultState}),
    getters: {
        getCameraView: (state) => {
            return state.cameraViews[state.activeCameraView - 1];
        },
    },
    actions: {
        reset(keys) {
            Object.assign(this, keys?.length
                ? _.pick(defaultState, keys)
                : defaultState // if no keys provided, reset all
            );
        },
        changeCameraView(){
            const markerStore = useMarkerStore();

            if (this.activeCameraView < this.cameraViews.length) {
                this.activeCameraView++;
            } else {
                this.activeCameraView = 1;
            }

            if(this.getCameraView === 'front' && this.focusMode){
                if(!markerStore.activeConfigurationMarker){
                    return
                }
                this.setFocusModeOn(markerStore.activeConfigurationMarker.xid);
            }else{
                event.emit("cameraView", {
                    value: this.getCameraView,
                });
            }

             //Set focusmode
             if(markerStore.isFloorActive && this.getCameraView === 'front') {
                this.focusMode = false;
            }
        },
        changeCameraZoom(type: string) {
            const camera = this.focusMode ? 'cameraFocusZoom' : 'cameraZoom';

            if (type === "out" && this[camera] < 10) {
                this[camera]++;
            }

            if (type === "in" && this[camera] > 0) {
                this[camera]--;
            }

            event.emit(camera, { value: this[camera] / 10 });
        },
        setFocusModeOn(objectId){
            event.emit("cameraFocus", { object_id: objectId });

            this.focusMode = true;
        },
        setFocusModeOff(){
            //Reset cameraFocus
            event.emit("cameraFocus", { object_id: '-1' });

            this.focusMode = false;
        },
        resetCameraView(){
            this.activeCameraView = 2;
            event.emit("cameraView", {
                value: this.getCameraView,
            });
        },
        toggleFocusMode(){
            if(this.focusMode){
                return this.setFocusModeOff();
            }

            const markerStore = useMarkerStore();
            if(!markerStore.activeConfigurationMarker){
                return;
            }
            this.setFocusModeOn(markerStore.activeConfigurationMarker.xid);
        },
        changeCameraRotation(direction) {
            this.rotateCamera(direction);

            this.cameraLoop = setInterval(() => {
                this.rotateCamera(direction);
            // }, 100);
            }, this.focusMode ? 50 : 100);
        },
        rotateCamera(direction: string){
            const camera = this.focusMode ? 'cameraFocusRotate' : 'cameraRotate';

            if (direction === "left") {
                if(this[camera] === 0){
                    this[camera] = 100;
                }
                // this[camera]--;
                this[camera] -= 2;
            }

            if (direction === "right") {
                if(this[camera] === 100){
                    this[camera] = 0;
                }
                // this[camera]++;
                this[camera] += 2;
            }

            event.emit(camera, { value: this[camera] / 100 });
        },
        clearInterval(){
            clearInterval(this.cameraLoop);
            this.cameraLoop = undefined;
        },
    }
});
