import { MuktaRegularXML, MuktaRegularBase64PNG } from './mukta_regular.font';
import { BitmapFont, BaseTexture, Texture } from 'pixi.js';

/**
 * Load custom font
 */
export function loadBitMapFont() {
    const image = document.createElement('img');
    image.setAttribute('src', MuktaRegularBase64PNG);
    const base = new BaseTexture(image);
    const texture = new Texture(base);
    
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(MuktaRegularXML, "text/xml"); //important to use "text/xml"

    BitmapFont.install(xmlDoc, texture);
}