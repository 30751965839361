import event from './eventBus';

export default class ObjectScene {
    date_time: string;
    north_offset: number;

    constructor(options) {
        this.date_time = options.date_time;
        this.north_offset = options.north_offset;
    }

    update() {
        event.emit('sceneSunstance', {
            "date_time": this.date_time,
            "north_offset": this.north_offset,
        });
    }
}