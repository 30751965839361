import * as _ from 'lodash-es';
import { defineStore } from "pinia";
import { useGeneralStore } from "./general";
import { useSortingStore } from "./sorting";
import axios from 'axios';
import { toRaw } from 'vue';
import type { PositionData } from '@/types/configurationmarkers.types';
import { DrawingType } from '@/types/drawinglogic.types';
import { Pagination } from '@/types/pagination';
import { useFilteringStore } from './filtering';
import { ConfigurationMarker } from '@/utils/classes/ConfigurationMarker';
import createRetailData from '@/utils/retailDataUtils';
import { Configuration } from '@/types/configuration.types';
import { Marker } from '@/utils/classes/Marker';
import { router } from '@inertiajs/vue3';
import { ProductVariant } from '@/utils/classes/ProductVariant';
import { useConfigurationStore } from './configuration';

const defaultState = {
    markers: [] as Marker[],
    defaults: {
        // Walls won't have any default product added to them.
        'wall_cladding': [],
    },
    configurationMarkers: [] as ConfigurationMarker[],
    activeConfigurationMarkerXid: null as number,
    productVariants: [] as ProductVariant[],
    productsLoading: false,
    pagination: null as Pagination,
    // checkedProductGroupVariant: null as ProductVariant,
    markerWaitingToBeReset: null,
    changedPrices: [],
    initialized: false,
    addingMarker: null,
}

export const useMarkerStore = defineStore('marker', {
    state: () => ({...defaultState}),
    getters: {
        checkedProductGroupVariant (state) {

            if (!this.activeConfigurationMarker || !this.selectedProductGroup) {
                return null;
            }

            return _.find(
                this.activeConfigurationMarker.productVariants,
                productVariant => productVariant.product_group_id == this.selectedProductGroup.id
            );
        },
        onLastPage (state) {
            return state.pagination
                && state.pagination.current_page == state.pagination.last_page;
        },
        total_price (state) {
            return state.configurationMarkers.reduce((price, configurationMarker) => {
                return price + configurationMarker.totalCost;
            }, 0);
        },
        primaryProductGroups (state) {
            return state.configurationMarkers.map(
                configurationMarker => {
                    return {
                        productGroup: configurationMarker.primaryProductGroup,
                        markerId: configurationMarker.marker.id,
                    }
                }
            ).filter(productGroups => productGroups !== undefined);
        },
        primaryProductGroupActive (state) {
            // instead of this, we will have to check if the primary configurable is active, no matter how "deep" inside of this configurable we are.
            return this.selectedProductGroup
                ? this.primaryProductGroups.some(item => item.productGroup == this.selectedProductGroup)
                : false;
        },
        activePrimaryProductGroup (state) {
            if (!this.activeConfigurationMarker || !this.primaryProductGroups) {
                return null;
            }

            return this.primaryProductGroups.find(
                item => item.markerId === this.activeConfigurationMarker.marker_id
            )?.productGroup;
        },
        activePrimaryProductVariant (state) {
            if (!this.activeConfigurationMarker || !this.activePrimaryProductGroup) {
                return null;
            }

            return this.activeConfigurationMarker.productVariants.find(
                variant => variant.product_group_id === this.activePrimaryProductGroup.id
            );
        },
        isProductCheckedAndPrimaryProductGroupActive (state) {
            if (!this.checkedProductGroupVariant) {
                return false;
            }
            return !!(this.primaryProductGroupActive && this.checkedProductGroupVariant);
        },
        checkedVariantPositioningRules (state) {
            if (!this.checkedProductGroupVariant) {
                return [];
            }

            return this.getRelevantPositioningRules(
                this.checkedProductGroupVariant,
                this.activeConfigurationMarker.productVariants.map(variant => variant.product_group_id),
            );
        },
        originalProductGroupVariant (state) {

            if (!this.selectedProductGroup || !this.activeConfigurationMarker) {
                return null;
            }

            return this.activeConfigurationMarker.originalVariant(this.selectedProductGroup);
        },
        isDirty (state) {
            return this.originalProductGroupVariant?.id != this.checkedProductGroupVariant?.id;
        },
        getConfigurationMarkerFromMarkers (state) {
            if (!this.activeConfigurationMarker) {
                return false;
            }

            return state.configurationMarkers.find((configurationMarker: { xid: number }) =>
                configurationMarker.xid === this.activeConfigurationMarker.xid
            );
        },

        drawingLogic (state) {
            if (!this.activeConfigurationMarker) {
                return null;
            }

            return this.activeConfigurationMarker.marker.drawing_logic.toLowerCase();
        },
        isFloorActive (state) {
            return this.drawingLogic === DrawingType.FLOOR;
        },
        lightingMarkers (state) {
            return state.configurationMarkers.filter(item => item.marker.drawing_logic === DrawingType.LIGHTING);
        },
        wallMarkers (state) {
            return state.configurationMarkers.filter(item => item.marker.drawing_logic === DrawingType.WALL);
        },
        activeWallMarker (state) {
            if (!this.activeConfigurationMarker) {
                return null;
            }

            return this.wallMarkers.find(wall => wall.position_data.wall === this.activeConfigurationMarker.position_data.wall);
        },
        activeConfigurationMarker (state): ConfigurationMarker | null {

            if (!state.initialized) {
                return null;
            }

            return state.configurationMarkers.find(
                configurationMarker => configurationMarker.xid === this.activeConfigurationMarkerXid
            ) as any;
        },
        activeConfigurationMarkerActions (state) {
            let actions = [];

            if (!this.activeConfigurationMarker) {
                return actions;
            }

            this.activeConfigurationMarker.productVariants.forEach(variant => {
                let triggeredActions = variant.triggered_actions || [];
                let productGroup = this.activeConfigurationMarker.productGroups
                    .find(group => group.id == variant.product_group_id);

                if (!productGroup || !productGroup.actions) {
                    return;
                }

                actions = [
                    ...actions,
                    ...productGroup.actions.filter(action => triggeredActions.includes(action.id)),
                ];
            });

            return actions;
        },
        selectedProductGroup (state) {
            const generalStore = useGeneralStore();

            if (generalStore.currentRoute !== 'client.configurations.modules.product-group') {
                return null;
            }

            return this.activeConfigurationMarker?.productGroups.find(productGroup => productGroup.id == generalStore.routeParams.productGroup);
        }
    },
    actions: {
        setMarkers (markers) {
            this.markers = markers.map(marker => new Marker(marker));
        },
        init(){
            this.initialized = true;
        },
        $reset() {
            this.configurationMarkers = [];
            this.activeConfigurationMarkerXid = null;
            this.clearProducts();
            this.initialized = false;
        },
        total_price_productvariants(configurationMarker: ConfigurationMarker){
            return getTotalPriceVariants(configurationMarker);
        },
        setActiveConfigurationMarker(configurationMarker: ConfigurationMarker) {
            this.activeConfigurationMarkerXid = configurationMarker.xid;

            router.visit(route('client.configurations.modules.module', { configuration: configurationMarker.configuration_id, marker: configurationMarker.marker.reference, module: configurationMarker.xid }));
        },
        removeConfigurationMarker(xid: string) {
            this.configurationMarkers = this.configurationMarkers.filter(m => m.xid !== xid);
        },
        addConfigurationMarker(configurationMarkerData) {

            // We create a deep copy of our configurationMarkerData here so we don't
            // accidentally modify the original data.
            // Modifying the original data may cause issues because we may inadvertently
            // add complex data to Inertia page props, and break the state of the page.
            const data = JSON.parse(JSON.stringify(configurationMarkerData));

            data.productVariants = data.productVariants.map(productVariant => new ProductVariant(productVariant));

            const configurationMarker = new ConfigurationMarker(data);

            this.configurationMarkers.push(configurationMarker);

            return this.configurationMarkers[this.configurationMarkers.length - 1];
        },
        removeProductVariant(productVariantId: number) {
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.productVariants = configurationMarker.productVariants.filter(item => item.id !== productVariantId);
        },
        addProductVariant(productVariant: ProductVariant) {
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.productVariants.push(productVariant);
        },
        getConfigurationMarkerByProductGroupId(productGroupId: number): ConfigurationMarker {
            return this.configurationMarkers.find((configurationMarker: ConfigurationMarker) =>
                configurationMarker.productGroups.find(productGroup => productGroup.id === productGroupId )
            );
        },
        changeMarkerEncaseMaterial(option: string){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.encasement_material = option;
        },
        changeMarkerPostionData(data){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.x = data.x;
            configurationMarker.position_data.y = data.y;
            configurationMarker.position_data.wall = data.wall;
            configurationMarker.position_data.side = data.side;
        },
        changeMarkerRotation(rotation){
            const configurationMarker = this.getConfigurationMarkerFromMarkers;
            configurationMarker.position_data.rotation = rotation;
        },
        startLoadingProducts(){
            this.clearProducts();

            // const generalStore = useGeneralStore();

            // generalStore.state = "choose-product";

            // this.activeConfigurationMarker = configurationMarker;

            // this.selectedProductGroup = productGroup;

            this.loadProducts();
        },
        clearProducts() {
            this.pagination = null;
            this.productVariants = [];
            this.productsLoading = false;
        },
        loadProducts () {

            // If we're already loading products, or we're on the last page
            // of results for the current query, cancel the request.
            if (this.productsLoading || this.onLastPage) {
                return;
            }

            this.productsLoading = true;

            let request = this.pagination
                ? axios.get(this.pagination.next_page_url)
                : this.newProductVariantRequest()

            request
                .then(response => response.data)
                .then(data => {
                    this.productVariants.push(...data.data.map(productVariant => new ProductVariant(productVariant)));
                    this.pagination = data.pagination;
                    this.productsLoading = false;

                    if (data.facets && data.facets.length) {
                        const FilteringStore = useFilteringStore();
                        FilteringStore.setFacetData(
                            this.selectedProductGroup.id,
                            data.facets,
                        );
                    }
            });
        },
        newProductVariantRequest()
        {
            let filters = [];

            const sortingStore = useSortingStore();

            let sort = sortingStore.getSortingForProductGroup(this.selectedProductGroup.id);

            const filteringStore = useFilteringStore();

            filters.push(...filteringStore.getFilterQueriesForProductGroup(this.selectedProductGroup.id).flat());

            this.activeConfigurationMarkerActions.filter(action => {
                return action.target_id == this.selectedProductGroup.id
                    && action.action.startsWith('filter-');
            }).forEach(action => {
                let source = this.activeConfigurationMarker.productVariants.find((variant: { product_group_id: any; }) => variant.product_group_id == action.source_id);

                if (!source) {
                    // We should trigger a warning in our error reporting system here!
                    return;
                }

                filters.push({
                    'type': action.action.split('filter-')[1],
                    'query': toRaw(action.action_data),
                    'source': source.id,
                });
            });

            return axios.get(route('client.configurations.modules.product-group-variants', {
                configuration: this.activeConfigurationMarker.configuration_id,
                marker: this.activeConfigurationMarker.marker.reference,
                module: this.activeConfigurationMarker.xid,
                productGroup: this.selectedProductGroup.id,
                filter: btoa(JSON.stringify(filters)),
                ...(!!sort ? {sort} : {}),
                page: this.pagination ? this.pagination.current_page + 1 : 1,
            }))
        },
        request2dMarkerReset(configurationMarker: ConfigurationMarker, variant: ProductVariant) {
            this.markerWaitingToBeReset = {
                configurationMarker,
                variant,
            }
        },
        detectChangedPrices () {
            this.changedPrices = this.configurationMarkers.reduce((accumulator, currentValue) => {
                return accumulator.concat(currentValue.getChangedPrices());
            }, []);
        },
        clearChangedPrices () {
            this.changedPrices = [];

            const configurationStore = useConfigurationStore();

            configurationStore.syncConfigurationChanges();
        },
        async createDefaultConfigurationMarkers(configuration: Configuration)
        {
            const configurationMarkers = await createRetailData(configuration);

            return configurationMarkers;
        },
        async createConfigurationMarker(
            markerReference: string,
            positionData: PositionData = {x:0, y:0},
            configurationId: string,
        ) {
            if (!(markerReference in this.defaults)) {
                await this.fetchMarkerDefaults(markerReference, configurationId);
            }

            return this.addConfigurationMarker({
                'configuration_id': configurationId,
                'position_data': positionData,
                'marker_id': this.getMarkerByReference(markerReference).id,
                'productVariants': this.defaults[markerReference],
            });
        },
        async fetchMarkerDefaults(markerReference, configurationId)
        {
            const response = await axios.get(route('client.markers.get-default-products', {
                marker: markerReference,
                configuration_id: configurationId,
            }));

            return this.defaults[markerReference] = response.data.map(productVariant => new ProductVariant(productVariant));
        },
        getMarkerByReference(markerReference: string) {
            return this.markers.find(marker => marker.reference == markerReference);
        },
    }
});

function getTotalPriceVariants(configurationMarker: ConfigurationMarker)
{
    return configurationMarker.totalCost;
}
