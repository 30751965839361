import type { MaterialOptions } from "@/types/material.types";
import type { ProductVariant } from "@/types/productvariant.types";

/**
 * Retrieves the material options based on the given variant.
 * @param {object} variant - The variant object.
 * @returns {MaterialOptions} - The material options.
 */
export function getMaterialOptions(variant: ProductVariant): MaterialOptions {
    const color_code = variant?.color_code ?? null;
    const tile_stagger = variant.stagger ?? 0;

    let grout_width = variant.grout_width ?? 0.5;
    let grout_color = variant.grout_color ?? '#83817B';
    let tile_bevel = variant.tile_bevel ?? 0;

    if (color_code) {
        tile_bevel = 0;
        grout_width = 0;
        grout_color = '';
    }

    return {
        tile_bevel: tile_bevel,
        grout_width: grout_width,
        grout_color: grout_color,
        color: color_code,
        sku: variant.model_reference,
        tile_stagger: tile_stagger,
        tile_width: variant.width,
        tile_height: variant.length,
    };
}