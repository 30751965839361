import DrawRegularGraphics, { addIcon } from "@/utils/classes/DrawRegularGraphics";
import type { PixiMarker } from "@/types/piximarker.types";
import type { Coordinates } from "@/types/coordinates.types";
import type { Polygon as PolygonObject } from "@/types/pixipolygon.types";
import type { ProductVariant } from "@/types/productvariant.types";
import { ConfigurationMarker } from '@/utils/classes/ConfigurationMarker';
import { useRoomStore } from "@/stores/rooms";

import {
    Polygon,
    SATVector
} from "detect-collisions";
import { Container, Sprite } from "pixi.js";
import { MarkerPoly, collisionSystem } from "@/types/collision.types";
import type { ShapePoint } from "@/types/pixishapepoints.types";
import { rotateVector, stayInsideRoom } from "./Collision";
import { Wall } from "@/utils/classes/Wall";
import DrawFloor from "../classes/DrawFloor";

type ObjectDimensions = {
    width: number;
    height: number;
}

type PositionData = {
    x: number;
    y: number;
    rotation: number;
}

/**
 * Get the correct marker position to send to the visualiser
 */
export function getMarkerPosition(marker: PixiMarker) {
    let markerPosition = {
        x: marker.x,
        y: -marker.y,
        rotation: marker.savedRotation,
    };
    // marker.rotation = marker.savedRotation;
    // let markerPosition = flipCoordinatesForUnity(marker);

    if (marker.wall) {
        //The offset we give to position the object in the 2d plan we need to deduct
        markerPosition.x -= marker.pivotOffsetX;
        markerPosition.y = -(marker.y - marker.pivotOffsetY);
    }

    return markerPosition;
}

/**
 * Flip the y coordinate to match the unity's coordinate system
 */
export function flipCoordinatesForUnity(position_data: PositionData): PositionData{
    return {
        x: position_data?.x || 0,
        y: position_data?.y * -1 || 0,
        rotation: position_data?.rotation * -1 || 0,
    };
}

/**
 * By providing an array of walls, find the closest wall based on x,y coordinates
 * Return object with the closest Wall
 */
export function getClosestWall(coordinates: Coordinates, includeFree = true, excludeWallIds: number[] = []): Wall{
    const roomStore = useRoomStore();
    const walls = roomStore.room.floorplan_data.walls as Wall[];

    let closestWall: Wall | undefined;
    let closestDistance = Infinity;

    walls.forEach(wall => {
        if (excludeWallIds.includes(wall.id)) {
            return; // Skip this wall
        }

        if(wall.free && !includeFree){
            return
        }

        const closestPoint = wall.getClosestPointOnLine(coordinates);

        //Calculate the distance of the coordinates to a wall
        const distance = Math.sqrt(Math.pow(coordinates.x - closestPoint.x, 2) + Math.pow(coordinates.y - closestPoint.y, 2));

        if (distance < closestDistance) {
            closestDistance = distance;
            closestWall = wall;
        }
    });

    closestWall.closestDistance = closestDistance;

    return closestWall;
}

function calculateDistance(point1: Coordinates, point2: Coordinates): number {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}

/**
 * By using the corners of the marker, we find the closest walls
 * We don't limit the projection of the corner points to the wall
 * Return array with closestWalls
 */
export function getClosestWalls(configurationMarker: ConfigurationMarker): Wall[]{
    const roomStore = useRoomStore();
    const walls = roomStore.room.floorplan_data.walls as Wall[];

    let threshold = 4; // Adjust as needed

    //We get the marker from the collisionSystem
    const markerBody = collisionSystem.all().find((body): body is MarkerPoly =>
        body instanceof MarkerPoly &&
        body.xid === configurationMarker.xid
    );

    //Get the corners positions from the marker
    const markerCorners = markerBody.calcPoints.map(corner => ({
        x: corner.x + markerBody.pos.x,
        y: corner.y + markerBody.pos.y
    }));

    const closestWalls = [];

    markerCorners.forEach((corner, index) => {
        //Combine corners to have sides
        const start = corner;
        const end = markerCorners[(index + 1) % markerCorners.length]; // Wrap around to the first point for the last side

        if (!end) {
            return
        }
        walls.forEach(wall => {
            const closestPointStart = wall.getClosestPointOnLine(start, false);
            const closestPointEnd = wall.getClosestPointOnLine(end, false);

            const distanceStart = calculateDistance(closestPointStart, start);
            const distanceEnd = calculateDistance(closestPointEnd, end);

            //Free walls are drawn from the center,
			//so the threshold should include half the wallthickness
			if(wall.free){
				threshold = threshold + (roomStore.wallThickness / 2)
			}

            if (distanceStart < threshold && distanceEnd < threshold) {
                closestWalls.push(wall);
            }
        });
    });

    return closestWalls;
}

/**
 * Detect what side the object is from the wall givin the coordinates
 */
// export function getSignedDistance(coordinates: Coordinates, wall: Wall){
//     const { from, to } = wall;
//     const dx = coordinates.x - from.x;
//     const dy = coordinates.y - from.y;

//     return (dx) * (to.y - from.y) - (dy) * (to.x - from.x);
// }

/**
 * Create a polygon from an object
 */
export function createObjectPolygon(x: number, y: number, object: ObjectDimensions, angle?: number): PolygonObject{
    const polygon = new Polygon(new SATVector(x, y), [
            new SATVector(-object.width, -object.height),
            new SATVector( object.width, -object.height),
            new SATVector( object.width,  object.height),
            new SATVector(-object.width,  object.height)
        ]);

    if(angle){
        polygon.setAngle(angle);
    }

    return polygon;
}

export function createRegularMarker(
    configurationMarker: ConfigurationMarker,
    product_variant: ProductVariant,
    wall: Wall,
    container: any
    ): PixiMarker {

    const regularGraphics = new DrawRegularGraphics({
        configurationMarker: configurationMarker,
        product_variant: product_variant,
        container: container,
        wall: wall,
    });

    return regularGraphics.markerGraphics;
}

/**
 * Delete a graphic from pixi 2D plan
 */
export function deleteGraphic(graphics: any, configurationMarker: ConfigurationMarker, container = null){
    const graphicObject = findGraphicsMarker(graphics, configurationMarker)
    const index = graphics.indexOf(graphicObject);

    //remove from collision system
    const markerBody = collisionSystem.all().find(body => body.xid === configurationMarker.xid)
    if(markerBody){
        collisionSystem.remove(markerBody);
    }

    //Remove possible rotation graphic
    const rotationGraphic = container.children.find(child => child.rotationConfigurationId === configurationMarker.xid);
    if(rotationGraphic){
        rotationGraphic.destroy({children: true});
    }

    //Remove the graphic and also remove the dynamic ref()
    graphicObject.destroy({children: true});
    graphics.splice(index, 1);
}

/**
 * Delete a wallGraphic from pixi 2D plan
 */
export function deleteWallGraphic(graphics: any, wallId: number, container: Container, shapePoints: ShapePoint[]){
    const roomStore = useRoomStore();

    const graphicObject = findGraphicsWallMarker(graphics, wallId);
    graphicObject.destroy({children: true});

    removeWallMeasurement(container, wallId);
    removeExistingPoints(shapePoints, container);
    roomStore.removeWall(wallId); //Remove from store
}

/**
 * Remove shapePoints for the free walls
 */
export function removeExistingPoints(shapePoints: ShapePoint[], container: any){
    // Remove each point from the container
    shapePoints.forEach((point) => {
        container.removeChild(point.graphics);
    });

    //reset shapePoints
    shapePoints = [];
}

//Hide any visible rotation graphics
export function hideAllRotationGraphics(container) {
    container.children
        .filter(child => child.rotationConfigurationId)
        .forEach(child => child.visible = false);
}

/**
 * Remove the wall measurements
 */
export function removeWallMeasurement(container: any, wallId: number){
    const wallMeasurementGraphic = findGraphicsWallMarker(container, wallId);
    wallMeasurementGraphic.destroy({children: true});
}

export function setNewIcon(graphic: any, icon: string, reference: string){
    if(!graphic.xid){
        return
    }

    const sprites = graphic.children.filter(child => child instanceof Sprite)

    graphic.removeChild(...sprites);

    addIcon(graphic, icon, reference);
}

export const findGraphicsMarker = (graphics: any, selectedMarker: ConfigurationMarker) => {
    if (graphics instanceof DrawFloor) {
        return graphics.floorGraphic;
    }

    if (graphics instanceof Array) {
        return graphics.find(marker => marker.xid === selectedMarker.xid);
    }

    return graphics;
}

export const findGraphicsWallMarker = (graphics: any, wallId: number) => {
    return graphics.children.find((wall) => wall.wall_id === wallId);
}

export function resize2DMarker(graphic: any, width: number, length: number) {
    // code to resize the 2D marker based on the width and length
    if(!graphic || graphic.radiator){
        return;
    }

    graphic.clear();
    graphic.lineStyle(2, 0xdbdbdb)
    graphic.beginFill(0xffffff);
    graphic.drawRoundedRect( -width / 2, -length / 2, width, length, 8);

    graphic.graphicLength = length;
    graphic.graphicWidth = width;
}

/**
 When creating a new marker and the marker is wall_mounted the object would 'spawn' in the center of the wall
 Only now we get the correct variant, so we calculate the correct position
 */
export function adjustWallMountedPositionNewMarker(
    wall: Wall,
    configurationMarker: ConfigurationMarker,
    product_variant: ProductVariant,
    newMarker: boolean,
) {
    const isRadiator = configurationMarker.marker.reference.includes('radiator');

    if(newMarker && configurationMarker.marker.wall_mounted){
        const sideToWall = {
            side: 'longside',
            object: (isRadiator ? 30 : product_variant?.length || 60) / 2,
            offset: 0,
        }
        const pos = stayInsideRoom(configurationMarker.position_data, wall, sideToWall)
        configurationMarker.position_data.x = pos.x;
        configurationMarker.position_data.y = pos.y;
    }
}
/**
 * Calculation the offset of the marker next to the wall given the angle
 * We draw a PixiMarker from the center, Unity draws the object from the pivot
 * So we need this offset to send to the visualizer
 */
export function getPivotOffset(
    angle: number,
    graphicLength: number,
    freeWall = false
    ): Coordinates {

    const halfGraphicLength = graphicLength / 2;
    const halfWallWidth = 8;

    const pivotOffset = {
        x: 0,
        y: freeWall ? halfGraphicLength + halfWallWidth : halfGraphicLength
    };
    // Convert angle to radians
    const angleRadians = angle * Math.PI / 180;

    return rotateVector(pivotOffset, angleRadians)
}
