import ObjectRoom from "@/events/ObjectRoom";
import event from "@/events/eventBus";
import { useRoomStore } from "@/stores/rooms";
import DrawWalls, { drawWall, setWallMeasurements } from "@/utils/classes/DrawWalls";
import { getClosestWall, removeExistingPoints } from "@/utils/pixiFloorplan/Marker";
import { snapToAngle } from "@/utils/objectUtils";
import { Container, Graphics } from "pixi.js";
import type { Room } from "@/types/room.types";
import type { Coordinates } from "@/types/coordinates.types";
import type { ShapePoint } from "@/types/pixishapepoints.types";
import * as Collision from '@/utils/pixiFloorplan/Collision';
import { debugCollision } from "@/types/collision.types";
import { Wall } from "@/utils/classes/Wall";
import ObjectScene from "@/events/ObjectScene";
import { useGeneralStore } from "@/stores";

/**
 * Draw the pixi walls and draw the room in unity
 */
export function DrawRoom(room: Room, container: Container): Graphics {
    const roomStore = useRoomStore();
    const wallThickness = roomStore.wallThickness;
    const wallColor = roomStore.wallColor;
    const og_walls = room.floorplan_data.walls;
    // const roomPoints = getRoomPoints(og_walls);
    // Collision.insertRoomCollisionTree(roomPoints);

    //Add walls to collision tree
    og_walls.forEach(wall => {
        Collision.insertWallCollisionTree(wall, wall.free)
    })

    //For unity We need to flip the y coordinate
    const room_walls = getUnityWalls(og_walls);
    //Get the roompoints again with flipped y values
    const unityPoints = getRoomPoints(room_walls);
    //Draw walls in unity
    drawUnityRoom(room_walls, unityPoints, room.room_height);

    //Draw walls in pixi
    return new DrawWalls({
        walls: og_walls,
        wallThickness: wallThickness,
        container: container,
        wallColor: wallColor,
    });
}

/**
 * Use the logic from drawing all the wall in the room to draw a single wall
 */
export function drawSingleWall(wall: Wall, container: any){
    const roomStore = useRoomStore();
    const wallThickness = roomStore.wallThickness;
    const wallColor = roomStore.wallColor;

    const graphics = drawWall(undefined, wall, true, 110, {width: wallThickness, color: wallColor, alignment: 0.5}, true);
    graphics.hitArea = graphics.getBounds();
    graphics.wall_id = wall.id;
    graphics.free = true;

    //Init the wall measurement
    setWallMeasurements(container, wall);

    return graphics;
}

/**
 * Move the wall inside the room
 * Or snap to closest wall when close enough to stay inside the room
 */
export function onDragMoveWall(draggable: any, mousePosition: Coordinates, shapePoints, container){
    const wallId = draggable.wall_id;

    const roundedMousePosition = roundMousePosition(mousePosition);

    //Remove points for changing the size of the wall
    removeExistingPoints(shapePoints, container);

    //To update a wall position you need to redraw the line
    updateWallByDragging(roundedMousePosition, wallId);

    redrawWall(draggable, wallId);
}

/**
 * Redraw an existing wall
 */
export function redrawWall(graphic: any, identifier: number){
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(identifier);
    const wallThickness = roomStore.wallThickness;
    const wallColor = roomStore.wallColor;

    graphic.clear();
    graphic.lineStyle(wallThickness, wallColor, 1);
    graphic.moveTo(currentWall.from.x, currentWall.from.y);
    graphic.lineTo(currentWall.to.x, currentWall.to.y);
    graphic.zindex = 100;
    graphic.hitArea = graphic.getLocalBounds();
}

/**
 * Update a free standing wall by dragging the wall around
 */
export function updateWallByDragging(
    mousePosition: Coordinates,
    wallId: number,
    ) {
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);

    const currentWallBody = Collision.findWallBody(wallId);

    const currentWallLength = currentWall.getLength();
    const closestWall = getClosestWall(mousePosition, false, [wallId]);

    const innerVector = closestWall.getInnerVector();
    const isSame = isSameAngle(currentWall.getAngle(), closestWall.getAngle());

    let newPos = mousePosition;

    const cosAngle = Math.cos(currentWall.getAngleRadians());
    const sinAngle = Math.sin(currentWall.getAngleRadians());
    const distance = ((innerVector.x * cosAngle) + (innerVector.y * sinAngle)) * currentWallLength;

    // if(!Collision.calculateDistWallToObject(closestWall, mousePosition, Math.abs(distance / 2))){
    if(isSame && !Collision.calculateDistWallToObject(closestWall, mousePosition, 10)){
        newPos = Collision.stayInsideRoom(mousePosition, closestWall, {
            side: '',
            object: Math.abs(distance / 2),
            offset: roomStore.wallThickness / 2
        });
    }

    currentWallBody.setPosition(newPos.x, newPos.y);
    debugCollision.update()

    updateWallPoints(currentWall, newPos);
}

function isSameAngle(angle1, angle2, range = 5) {
    // Normalize angles to the same range for consistency
    angle1 = ((angle1 % 180) + 180) % 180; // Normalize angle1 to [0, 180)
    angle2 = ((angle2 % 180) + 180) % 180; // Normalize angle2 to [0, 180)

    // Check if the absolute difference between angles is within the range
    return Math.abs(angle1 - angle2) <= range;
}

function updateWallPoints(currentWall: Wall, coordinates: Coordinates) {
    const offsetX = (currentWall.to.x - currentWall.from.x)/ 2;
    const offsetY = (currentWall.to.y - currentWall.from.y)/ 2;

    currentWall.from.x = coordinates.x - offsetX;
    currentWall.from.y = coordinates.y - offsetY;
    currentWall.to.x = coordinates.x + offsetX;
    currentWall.to.y = coordinates.y + offsetY;
}

/**
 * Rezise a wall by moving the pointers around
 */
export function updateWallByPointers(mousePosition: Coordinates, point: ShapePoint): void{
    const wallId = point.identifier;
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);
    const wallThickness = roomStore.wallThickness;
    const isFirstPoint = point.id === 1;
    const closestWall = getClosestWall(mousePosition, false);

    //Update the wall by mousePosition
    let newPos = updateWall(isFirstPoint, currentWall, mousePosition)

    //When you get to close to the outer wall, ensure you can't drag out
    if(!Collision.calculateDistWallToObject(closestWall, mousePosition, 5)){
        const { x, y } = snapToClosestOuterWall(mousePosition, closestWall, wallThickness);

        //Update the wall by locked x,y coordinated, and disable snapping
        newPos = updateWall(isFirstPoint, currentWall, {x,y}, false)
    }

    point.x = newPos.x;
    point.y = newPos.y;

    Collision.removeWallCollisionTree(wallId);
    Collision.insertWallCollisionTree(currentWall, true)
}

function snapToClosestOuterWall(
    mousePosition: Coordinates,
    closestWall: Wall,
    wallThickness: number,
    ): Coordinates {
    //Ensure you stay inside the room
    const { x, y } = Collision.stayInsideRoom(mousePosition, closestWall);

    //Add displacement to the coordinates so the wall would snap inside the room wall
    const innerVector = closestWall.getInnerVector();
    const displacementX = innerVector.x * wallThickness / 2;
    const displacementY = innerVector.y * wallThickness / 2;

    return {
        x: x - displacementX,
        y: y - displacementY,
    };
}

function updateWall(
    isFirstPoint: boolean,
    currentWall: Wall,
    mousePosition: Coordinates,
    useSnapping = true,
    ): Coordinates {
    const minWallLength = 50;

    // Update the wall coordinates based on the dragged point's new position
    if (isFirstPoint) {
        currentWall.from.x = mousePosition.x;
        currentWall.from.y = mousePosition.y;
    }else{
        currentWall.to.x = mousePosition.x;
        currentWall.to.y = mousePosition.y;
    }

    // Calculate the angle of the wall
    const angleRadians = currentWall.getAngleRadians();
    const snappedAngle = useSnapping ? snapToAngle(angleRadians, 45, 5) : angleRadians;

    // Update the wall coordinates based on the snapped angle
    const length = Math.max(currentWall.getLength(), minWallLength);

    if (isFirstPoint) {
        currentWall.from.x = currentWall.to.x - length * Math.cos(snappedAngle);
        currentWall.from.y = currentWall.to.y - length * Math.sin(snappedAngle);

        return currentWall.from;
    }

    currentWall.to.x = currentWall.from.x + length * Math.cos(snappedAngle);
    currentWall.to.y = currentWall.from.y + length * Math.sin(snappedAngle);

    return currentWall.to;
}

export function roundMousePosition(mousePosition: Coordinates): Coordinates {
    return {
        x: Math.round(mousePosition.x),
        y: Math.round(mousePosition.y),
    };
}

/**
 * Change all y coordinates to negative, unity's y axis starts from bottom left
 * Pixijs y axis starts from top left
 */
export function getUnityWalls(walls: Wall[]): Wall[]{
    return walls.map((wall) => {
        const { from, to } = flipWallCoordinatesForUnity(wall);

        return {
            id: wall.id,
            free: wall.free,
            from: from,
            to: to,
            height: wall.height,
        };
    });
}

export function flipWallCoordinatesForUnity(position_data: {from: Coordinates; to: Coordinates}){
    return {
        from: {
            x: position_data.from.x,
            y: position_data.from.y * -1,
        },
        to: {
            x: position_data.to.x,
            y: position_data.to.y * -1,
        },
    };
}

/**
 *  Get points from walls, they are needed to draw the floor in the visualizer
 *  exclude walls that are free
 */
export function getRoomPoints(walls: Wall[]){
    return walls.filter((wall) => !wall.free)
                .map((wall) => {
                    return wall.from;
                });
}

/**
 * Draw the walls in the visualizer
 */
export function drawUnityRoom(walls: Wall[], points: Coordinates[], room_height: number){
    const generalStore = useGeneralStore();

    ObjectRoom.draw(walls, points, room_height);

    //Set default sunstance
    new ObjectScene(generalStore.sunStance).update();

    event.emit("sceneBackground", { id: 2 });
}

/**
 *  Get the biggest size of all the walls
 */
export function getMaxValues(walls: Wall[]) {
    let maxX: number, maxY: number;

    //check if walls undefined and return error
    if (!walls) {
        console.error("walls undefined");
        return null;
    }

    walls.forEach((wall) => {
        maxX = Math.max(maxX || 0, wall.from.x, wall.to.x);
        maxY = Math.max(maxY || 0, wall.from.y, wall.to.y);
    });

    return { x: maxX, y: maxY };
}

export function resetTint (graphics: any[]): void {
    graphics.forEach(child => {
        child.tint = 0xffffff;
    });
};

/**
 * Update wall measurements
 */
export function updateWallMeasurements(parent: Container, wallId: number, updateText = false){
    const roomStore = useRoomStore();
    const currentWall = roomStore.getWall(wallId);

    if (!currentWall) {
        return;
    }

    const halfWallWidth = roomStore.wallThickness / 2;

    //Get the correct wall measurement
    const measurementGraphics = parent.children.find(
        child => child.wall_id === wallId
    );

    if(!measurementGraphics){ return }

    const midPoint = currentWall.getCenter();

    if(!currentWall.free){
        const innerVector = currentWall.getInnerVector();

        midPoint.x -= (halfWallWidth * innerVector.x);
        midPoint.y -= (halfWallWidth * innerVector.y);
    }

    measurementGraphics.angle = currentWall.getAngle() % 180;

    //Set the wall measurement to the correct position
    measurementGraphics.position.set(midPoint.x, midPoint.y);
    measurementGraphics.visible = true;

    if(!updateText){ return }

    //Update text
    const wallLength = parseFloat((currentWall.getLength() / 100).toFixed(2));

    measurementGraphics.getChildByName("measurementText").text = wallLength + " m";
}
