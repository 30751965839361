export default {
    'global': {
        //Tekenen van de kamer op basis van punten, ook de hoogte van de buitenmuren wordt meegegeven
        //De visualizer gebruikt default values voor dikte en materiaal van de muren
        //We sturen de muren mee zodat deze al de ID krijgen om later materiaal te kunnen updaten
        'roomDraw': {
            name: 'room.draw',
            transform: (event: Object) => event,
        },
        //Deprecated
        'roomClear': {
            name: 'room.clear',
            transform: (event: Object) => event,
        },
        //Op basis van ID, update materiaal van de muur of vloer
        //Indien tegels ook meerdere parameters mogelijk (stagger, grout, tile size)
        'roomMaterialUpdate': {
            name: 'room.material.update',
            transform: (event: Object) => event,
        },
        
        //Tekenen van een vrijstaande muur, ID + start en end positie, height
        'wallDraw': {
            name: 'wall.create',
            transform: (event: Object) => event,
        },
        //Zelfde event als hierboven maar met andere coordinaten (verplaatsen / verkleinen / vergroten van vrijstaande muur)
        'wallUpdate': {
            name: 'wall.update',
            transform: (event: Object) => event,
        },
        //Verwijderen van de vrijstaande muur
        'wallDelete': {
            name: 'wall.delete',
            transform: (event: Object) => event,
        },
        
        //Aanmaken van een zone op een muur (wall_id) met materiaal en coordinaten van de zone
        //Coordinaten startpunt onderaan -> eindpunt rechtsboven
        //Side: Front - Back 
        //Bij het verslepen verwijderen we de zone en maken we ze gewoon opnieuw aan
        'wallZoneCreate': {
            name: 'wall.zone.create',
            transform: (event: Object) => event,
        },
        //Deprecated
        'wallZoneUpdate': {
            name: 'wall.zone.update',
            transform: (event: Object) => event,
        },
        //Verwijderen van zone
        'wallZoneDelete': {
            name: 'wall.zone.delete',
            transform: (event: Object) => event,
        },

        //Inzoomen op de volledige kamer
        'cameraZoom': {
            name: 'camera.zoom',
            transform: (event: Object) => event,
        },
        //Camera view plaatsen in topview of default view
        'cameraView': {
            name: 'camera.view',
            transform: (event: Object) => event,
        },
        //Roteren van de volledige kamer
        'cameraRotate': {
            name: 'camera.orbit',
            transform: (event: Object) => event,
        },
        //Na het klikken op een object, sturen we de ID van het aangeklikte object
        'cameraFocus': {
            name: 'camera.focus',
            transform: (event: Object) => event,
        },
        //Roteren van camera wanneer object in focus is
        'cameraFocusRotate': {
            name: 'camera.focus.orbit',
            transform: (event: Object) => event,
        },
        //Inzoomen van camera wanneer object in focus is
        'cameraFocusZoom': {
            name: 'camera.focus.zoom',
            transform: (event: Object) => event,
        },

        //Initialiseren van basis object met object_id, locatie (x,y), rotatie 
        'objectCreate': {
            name: 'object.create',
            transform: (event: Object) => event,
        },
        //Verwijderen van basis object met object_id
        'objectDelete': {
            name: 'object.delete',
            transform: (event: Object) => event,
        },
        //Nieuwe positie van het basis object (x,y)
        'objectMove': {
            name: 'object.move',
            transform: (event: Object) => event,
        },
        //Aan het basis object een sku meegeven met eventueel height offset, encase + materiaal, addons (modifiers)
        'objectModelSet': {
            name: 'object.model.set',
            transform: (event: Object) => event,
        },
        //Mogelijks Deprecated
        'objectModelDelete': {
            name: 'object.model.delete',
            transform: (event: Object) => event,
        },
        //Wanneer je het basis object hebt, voeg een model toe aan het basis object met alles van positionerings regels
        //model_identifier = modular sku
        //socket_identifier = van waar moeten de positionerings regels starten? 
        'objectModularUpdate': {
            name: 'object.modular.update',
            transform: (event: Object) => event,
        },
        //Verwijderen van het extra model van het basis object
        'objectModularDelete': {
            name: 'object.modular.delete',
            transform: (event: Object) => event,
        },

        //Lichtsterkte + kleur wijzigen (alleen in dev mogelijk momenteel, alle lichtpunten aangestuurd kan individueel)
        'objectLightingUpdate': {
            name: 'object.lighting.update',
            transform: (event: Object) => event,
        },

        //Tijd en locatie van de zon
        'sceneSunstance': {
            name: 'scene.sunstance',
            transform: (event: Object) => event,
        },
        //Deprecated, unity voorziet een vaste grijze achtergrond
        'sceneBackground': {
            name: 'scene.background',
            transform: (event: Object) => event,
        },

        //Bij het opslaan van de configuratie, maken we de configuratie aan.
        //We sturen de configuratie id door naar de visualizer en deze neemt screenshots
        //Deze worden doorgestuurd via een webhook naar ons cms systeem
        'screenshot': {
            name: 'screenshot',
            transform: (event: Object) => event,
        },
        //Is het gelukt om screenshots te nemen, de screenshots te sturen?
        'screenshotResponse': {
            name: 'screenshot.response',
            transform: (event: Object) => event,
        },

        //ScreensaverImages + logo van de klant
        //Gebruik voor een image carousel loading state in the visualizer
        'branding': {
            name: 'branding',
            transform: (event: Object) => event,
        },

        //Eerste event die gestuurd wordt bij het tekenen van de kamer om te zeggen dat we willen starten met een nieuwe configuratie
        'sessionStart': {
            name: 'session.open',
            transform: (event: Object) => event,
        },
        //Afsluiten van de inspratietool
        'sessionStop': {
            name: 'session.close',
            transform: (event: Object) => event,
        },
        //Indien scene + initiele objecten gestuurd zijn is de laatste event hiervan een session reveal om de visualisatie te tonen
        'sessionReveal': {
            name: 'session.reveal',
            transform: (event: Object) => event,
        },
    }
}
