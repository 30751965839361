<script lang="ts">
import Color from 'color';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';
import { useGeneralStore } from '@/stores';

const fullConfig = resolveConfig(tailwindConfig)

export default {
    computed: {
        brandColor () {
            return Color(useGeneralStore()?.client?.color ?? '#143756');
        },
        secondaryColor () {
            return Color(useGeneralStore()?.client?.secondary_color ?? '#459bda')
        },
        brandHue () {
            return this.brandColor.hue();
        },
        defaultGrays () {
            return fullConfig.theme.colors.neutral;
        },
        brandGrays () {
            if (this.colorIsNeutral(this.brandColor)) {
                return this.defaultGrays;
            }

            return Object.fromEntries(Object.entries(this.defaultGrays).map(([name, code]) => {
                return [name, this.transformColor(code)];
            }));
        },
        graysCSS () {
            let css = '';

            Object.keys(this.brandGrays).forEach(key => {
                css += `--color-brand-gray-${key}: ${this.channels(this.brandGrays[key])};`
            });

            return css;
        },
        // CSS color variables are defined as just the RGB channels.
        // This is done for compatibility with Tailwind’s color opacity modifiers.
        // https://tailwindcss.com/docs/customizing-colors#using-css-variables
        styles () {
            return `
                :root {
                    --color-secondary: ${this.channels(this.secondaryColor)};
                    --color-brand-300: ${this.channels(this.brandColor
                        .desaturate(0.25)
                        .lighten(0.15))};
                    --color-brand-400: ${this.channels(this.brandColor
                        .desaturate(0.125)
                        .lighten(0.075))};
                    --color-brand-500: ${this.channels(this.brandColor)};
                    --color-brand-600: ${this.channels(this.brandColor
                        .desaturate(0.25)
                        .darken(0.15))};
                    --color-brand-700: ${this.channels(this.brandColor
                        .desaturate(0.5)
                        .darken(0.3))};
                    ${this.graysCSS}
                }
            `;
        },
    },
    methods: {
        channels(color) {
            return color.rgb().array().join(' ');
        },
        // Transform a color by mixing the hue of the brand color into it, based on the perceived lightness for the provided color.
        transformColor (color) {
            color = Color(color);

            const saturation = Math.max(4, Math.abs(color.lightness() - 50) / 10 * 4);

            return Color(`hsl(${this.brandHue}, ${saturation}%, ${color.lightness()}%)`);
        },
        // Determine if a color would be perceived as a neutral color.
        colorIsNeutral (color) {
            return color.saturationl() < 4
                || color.lightness() > 96
                || color.lightness() < 4;
        }
    },
}
</script>

<template>
    <teleport to="head">
        <component :is="'style'" v-html="styles"></component>
    </teleport>
</template>
