import event from './eventBus';
import { getMaterialOptions } from '../etc/material';
import type { MaterialOptions } from '@/types/material.types';

export default class ObjectRoom {
    wall_id: number;
    variant: any;

    private materialOptions: MaterialOptions;

    constructor(options) {
        this.wall_id = options.wall_id;
        this.variant = options.variant;
        this.materialOptions = getMaterialOptions(this.variant);
    }

    materialUpdate() {
        event.emit('roomMaterialUpdate', {
            "identifier": this.wall_id,
            "type": this.wall_id ? 'wall' : 'floor',
            "material": this.materialOptions,
        });
    }
     
    static draw(walls, points, room_height) {
        event.emit("roomDraw", { 
            walls, 
            points, //Needed for room structure
            room_height: room_height 
        });
    }
}