import event from './eventBus';

export default class WallObject {
    id: number;
    height: number;
    from: object;
    to: object;

    constructor(options) {
        this.id = options.id;
        this.height = options.height;
        this.from = options.from;
        this.to = options.to;
    }

    draw() {
        event.emit('wallDraw', {
            "id": this.id,
            "from": this.from,    
            "to": this.to,    
            "height": this.height,
        });
    }

    update() {
        event.emit('wallUpdate', {
            "id": this.id,
            "from": this.from,
            "to": this.to,
            "height": this.height,
        });
    }

    delete() {
        event.emit('wallDelete', {
            "id": this.id,
        });
    }
}