import { defineStore } from "pinia";
import _ from "lodash";
import axios from 'axios';
import { useGeneralStore } from "./general";
import { ConfigurationMarker } from "@/types/configurationmarkers.types";

const defaultState = {
    shapeType: null,
    shapeComplete: false,
    wallSizeEditorActive: false,
    wallSizeEditorValue: "0",
    wallInterfacePosition: {},
    // freeWallActive: false,
    // controls: {
    //     deleteWall: false
    // },
    previousData: {},
    storingRoomData: false,
};

export const useDrawingStore = defineStore('drawing', {
    state: () => ({...defaultState}),
    actions: {
        completeShape() {
            this.shapeComplete = true;
        },
        updateShapeType(shapeType) {
            this.shapeType = shapeType;
        },
        resetWallSizeEditor() {
            this.wallSizeEditorValue = "0";
            this.wallSizeEditorActive = false;
        },
        reset(keys = null) {
            Object.assign(this, keys?.length
                ? _.pick(defaultState, keys)
                : defaultState // if no keys provided, reset all
            );
        },
        async storeRoomData(roomData, defaultConfigurationMarkers: ConfigurationMarker[] = []) {
            const generalStore = useGeneralStore();

            this.storingRoomData = true;

            try {
                const response = await axios.post(route('client.configurations.store'), {
                    room: JSON.parse(JSON.stringify(roomData)),
                    room_type: 'bathroom',
                    vat: generalStore.vatAmount.toString(),
                    configurationMarkers: defaultConfigurationMarkers,
                });

                this.storingRoomData = false;

                return response.data;
            } catch (error) {
                this.createNotification({
                    type: 'error',
                    message: error.message,
                });

                this.storingRoomData = false;
            }
        }
    }
});
