import { ref } from "vue";
import { createUUID } from '../etc/uuid';
import type { Notification, CreateNotification } from "@/types/notifications.types";
import i18n from '../lang/i18n.js';

// provides sensible defaults for our notifications.
const defaultNotificationOptions = {
    type: "info",
    title: "",
    message: "",
    autoClose: true,
    duration: 5,
};


export default function useNotifications() {
    // Reactive array of notifications.
    const notifications = ref<Notification[]>([]);

    // function to create notification
    const createNotification: CreateNotification = (options) => {
      const _options = Object.assign({ ...defaultNotificationOptions }, options);

      _options.title = _options.title || i18n.global.t(`default_notification_titles.${_options.type}`);

      notifications.value.push(
        ...[
          {
            id: createUUID(),
            ..._options,
          },
        ]
      );
    };

    // function to remove notification
    const removeNotifications = (id: string) => {
      const index = notifications.value.findIndex((item) => item.id === id);
      if (index !== -1) notifications.value.splice(index, 1);
    };

    // The two functions below are more for body
    // overflow when creating notifications that slide
    // in from outside the viewport. We will not be
    // using them for now but good to have.
    const stopBodyOverflow = () => {
      document && document.body.classList.add(...["hide-overflow"]);
    };

    const allowBodyOverflow = () => {
      document && document.body.classList.remove(...["hide-overflow"]);
    };

    return {
      notifications,
      createNotification,
      removeNotifications,
      stopBodyOverflow,
      allowBodyOverflow,
    };
}
