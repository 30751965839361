import type { ProductVariant } from '@/types/productvariant.types';
import { ConfigurationMarker } from '@/utils/classes/ConfigurationMarker';
import event from './eventBus';
import type { Addon } from '@/types/configurationmarkers.types';

export default class ObjectModel {
    public object_id: number;
    public model_reference: string;

    private type: string;
    private height_offset: number;
    private encasing: boolean;
    private encasement_material: string;
    private addons: Addon[];

    constructor(
        configurationMarker: ConfigurationMarker,
        variant: ProductVariant,
    ) {
        this.object_id = configurationMarker.xid;
        this.type = configurationMarker.marker.name;
        this.encasing = configurationMarker.marker.encasing;
        this.encasement_material = configurationMarker.position_data.encasement_material ?? '';

        this.model_reference = variant.model_reference;
        this.height_offset = variant.height_offset;
        this.addons = [];

        // Add addon if variant is adjustable
        if (variant.adjustable) {
            this.updateAddon({
                "id": 0,
                "type": "adjust_model",
                "width": variant.width,
                "height": variant.height,
                "position": 0
            });
        }
    }

    updateAddon(updateAddon: Addon) {
        const existingAdjustAddonIndex = this.addons.findIndex(addon => addon.type === updateAddon.type);

        if (existingAdjustAddonIndex !== -1) {
            // If adjust_model addon already exists, update it
            this.addons[existingAdjustAddonIndex] = updateAddon;
        } else {
            // If adjust_model addon doesn't exist, add it
            this.addons.push(updateAddon);
        }
        // this.addons.push(addon);
    }

    updateHeightOffset(heightOffset: number){
        this.height_offset = heightOffset;
    }

    set() {
        event.emit('objectModelSet', {
            "object_id": this.object_id,
            "sku": this.model_reference,
            "type": this.type,
            "height_offset": this.height_offset,
            "encase": this.encasing,
            "encasement_material": this.encasement_material,
            "addons": this.addons,
        });
    }

    delete() {
        event.emit('objectModelDelete', {
            "object_id": this.object_id,
            "sku": this.model_reference,
        });
    }
}
