import event from './eventBus';

export default class BaseObject {
    object_id: number;
    point: any;
    marker_type: string;
    carve: boolean;
    marker_positioning: string;

    constructor(options) {
        this.object_id = options.object_id;
        this.point = options.point;
        this.marker_type = options.drawing_logic;
        this.carve = options.carve, //can't be null or empty //when carved, add a hole in the wall or floor size of the object
        this.marker_positioning = options.marker_positioning //when wall_based, the object will be hidden at camera front view
    }

    create() {
        event.emit('objectCreate', {
            "object_id": this.object_id,
            "point": this.point,
            "marker_type": this.marker_type,
            "carve": this.carve,
            "positioning_base": this.marker_positioning,
        });
    }

    delete() {
        event.emit('objectDelete', {
            "object_id": this.object_id,
        });
    }

    move() {
        event.emit('objectMove', {
            "object_id": this.object_id,
            "point": this.point,
        });
    }
}
