import event from './eventBus';
import { getMaterialOptions } from '../etc/material';
import type { MaterialOptions } from '@/types/material.types';

export default class WallZoneObject {
    id: string;
    wall_id: number;
    side: string;
    variant: any;
    from: object;
    to: object;

    private materialOptions?: MaterialOptions;

    constructor(options) {
        this.id = options.id;
        this.wall_id = options.wall_id;
        this.side = options.side;
        this.variant = options.variant;
        this.materialOptions = this.variant ? getMaterialOptions(this.variant) : undefined;
        this.from = options.from;
        this.to = options.to;
    }

    create() {
        event.emit('wallZoneCreate', {
            "id": this.id,
            "wall_id": this.wall_id,
            "material": this.materialOptions,
            "side": {'a': 'front', 'b': 'back'}[this.side] || this.side, //remove the 'a','b' after a year or so
            "from": this.from,    
            "to": this.to,    
        });
    }

    update() {
        event.emit('wallZoneUpdate', {
            "id": this.id,
            "material": this.materialOptions,
            "from": this.from,
            "to": this.to,
        });
    }

    delete() {
        event.emit('wallZoneDelete', {
            "id": this.id,
            "wall_id": this.wall_id,
        });
    }
}