import type { Coordinates } from "./coordinates.types";
import { System, Polygon, Line } from "detect-collisions";
import { RaycastHit, Body } from "detect-collisions/dist/model";

// export class RoomPoly extends Polygon {
//     constructor(position, points, options) {
//         super(position, points, options);
//         this.isRoom = true;
//     }
//     isRoom = true
// }

export class WallPoly extends Polygon {
    constructor(position, points, wall_id, options) {
        super(position, points, options);
        this.wall_id = wall_id;
        this.isFreeWall = true;
    }
    wall_id = null;
    isFreeWall = true;
}

export class WallLine extends Line {
    constructor(start, end, wall_id, options) {
        super(start, end, options);
        this.wall_id = wall_id;
        this.isWall = true;
    }
    wall_id = null;
    isWall = true;
}

export class MarkerPoly extends Polygon {
    constructor(position, points, xid, options) {
        super(position, points, options);
        this.xid = xid;
        this.isMarker = true;
    }
    xid = null;
    isMarker = true;
}

class Blueprint extends System<
// RoomPoly |
WallPoly |
WallLine |
MarkerPoly |
Line
> {}

export const collisionSystem = new Blueprint();

export const debugCollision = {
    legend: "Test Legend",
    physics: collisionSystem,
    drawCallback: () => {
      // draw callback function
    },
    update: () => {
      // update function to update bodies
    }
  };


interface RaycastSet {
    raycasts: { start: Coordinates, end: Coordinates }[];
    hasMissed: boolean;
    closestDistance: number;
    closestHit: RaycastHit<Body> | null;
    closestVector: Coordinates;
};

interface RaycastSets {
    left: RaycastSet;
    right: RaycastSet;
    raycastLines: Line[];
}


function createRaycastSets(): {
    left: RaycastSet;
    right: RaycastSet,
    raycastLines: Line[],
    raycastHits: RaycastHit<Body>[]
} {
    const createRaycastSet = (): RaycastSet => ({
        raycasts: [],
        hasMissed: false,
        closestDistance: Infinity,
        closestHit: null,
        closestVector: { x: 0, y: 0 },
    });

    return {
        left: createRaycastSet(),
        right: createRaycastSet(),
        raycastLines: [],
        raycastHits: [],
    };
}

export { RaycastSet, RaycastSets, createRaycastSets }
