import { useMarkerStore } from '@/stores';
import { Configuration } from '@/types/configuration.types';
import * as Room from '@/utils/pixiFloorplan/Room';

export default async function createRetailData(configuration: Configuration) {

    const configurationMarkers = [];
    const markerStore = useMarkerStore();

    let walls = configuration.room.floorplan_data.walls;
    let max_wall_sizes = Room.getMaxValues(walls);

    // Create a configuration marker for the light and add it to the middle of the room.
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        'lighting',
        { x: max_wall_sizes.x / 2, y: max_wall_sizes.y / 2 },
        configuration.id
    ));

    // Create a configuration marker for the floor cladding
    configurationMarkers.push(await markerStore.createConfigurationMarker(
        'floor',
        undefined,
        configuration.id
    ));

    // For each wall, create a configuration marker for the wall cladding
    for (var i = 0; i < walls.length; i++) {

        configurationMarkers.push(await markerStore.createConfigurationMarker(
            'wall_cladding',
            { wall: walls[i].id },
            configuration.id
        ));
    }

    return configurationMarkers;
}
