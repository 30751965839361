import event from './eventBus';

export default class ObjectModular {
    object_id: Number;
    variant: any;
    positioning_name: String;
    positioning_rule: any;

    constructor(options) {
        this.object_id = options.object_id;
        this.variant = options.variant;
        this.positioning_name = options.positioning_name;
        this.positioning_rule = options.positioning_rule;
    }

    private getTargetType() {
        if (this.positioning_rule.dummy) return 'point';
        return this.positioning_rule.object_multiplier > 1 ? 'dynamic_boundingbox_location' : 'boundingbox_location'
    }

    private getBoundingBoxValue() {
        if (this.positioning_rule.dummy) {
            return this.positioning_rule.dummy.default_name;
        }

        return {
            x: { left: 'left', center: 'center', right: 'right' }[this.positioning_rule.bounding_box_x_position],
            y: { top: 'up', center: 'center', bottom: 'down', floor: 'floor' }[this.positioning_rule.bounding_box_y_position],
            z: { front: 'front', center: 'center', back: 'back', wall: 'wall' }[this.positioning_rule.bounding_box_z_position],
        };
    }

    private getWallAxis() {
        if (!this.positioning_rule.dummy) return null;

        const wallAxisMapping = {
            none: null,
            behind: '-z',
            left: '-x',
            right: 'x'
        };
        return wallAxisMapping[this.positioning_rule.dummy_fixed_wall] || null;
    }

    private buildTargetRule() {
        const { object_multiplier } = this.positioning_rule;

        const targetRule: any = {
            socket_identifier: this.positioning_rule.product_group_id ?? -1, //extra specificatie dat de target onder een andere socket identifier te zoeken is
            type: this.getTargetType(),
            value: this.getBoundingBoxValue(),
        };

        // Only add wall_axis if it's a "point" type. This will move our object to the closest wall in the given direction.
        // Options are z, -z, x, -x (y won't work because floor and ceiling aren't technically walls).
        if (targetRule.type === 'point') {
            targetRule.wall_axis = this.getWallAxis();
        }

        // Only add count, axis, and method if object_multiplier > 1.
        if (object_multiplier > 1) {
            targetRule.count = object_multiplier; //default = 1, example taps on double vanity = 2
            targetRule.axis = 'x'; // for now only one positioning is added.
            targetRule.method = 'half'; //for positioning get bounding box axis and divide by half.
        }

        return targetRule;
    }


    update() {
        const { priority, offset_x, offset_y, offset_z, rotation } = this.positioning_rule;

        event.emit('objectModularUpdate', {
            'object_id': this.object_id,
            'socket_identifier': this.variant.product_group_id,
            'model_identifier': this.variant.model_reference,
            'positioning_rules': [{
                //'type': 'position_rule', //Added in the visualizer as a dynamic systeem, currently defaults to 'position_rule'
                'rule': {
                    'priority': priority, //required for some reason for the visualizer and is needed in the request'
                    'target':this.buildTargetRule(),
                    'verb': {
                        'type': 'place_at',
                        'offset': {
                            'x': offset_x,
                            'y': offset_y,
                            'z': offset_z
                        },
                        'rotation': rotation,
                    }
                }
            }],
        });
    }

    delete() {
        event.emit('objectModularDelete', {
            'object_id': this.object_id,
            'socket_identifier': this.variant.product_group_id,
        });
    }
}
