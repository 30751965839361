import svgToDataUri from 'mini-svg-data-uri';

// Apply the opacity value to CSS variables if it's defined.
// https://tailwindcss.com/docs/customizing-colors#using-css-variables
function withOpacityValue(variable) {
    return ({ opacityValue }) => {
        if (opacityValue === undefined) {
            return `rgb(var(${variable}))`;
        }
        return `rgb(var(${variable}) / ${opacityValue})`;
    }
}

//Transform module.export to export default https://stackoverflow.com/questions/66402879/in-vite2-how-to-import-an-esmodule-in-tailwind-config-js/66406070#66406070
//ESM not supported yet https://github.com/tailwindlabs/tailwindcss/issues/8029#issuecomment-1086875656
//Change it to module.export if you want Tailwind intellisense highlights
// module.exports = {
export default {
    content: [
        './resources/views/client.blade.php',
        './resources/views/nova/*.blade.php',
        // './resources/views/render/*.blade.php',
        './resources/scripts/**/*.{vue,js,ts,jsx,tsx}',
    ],
    // plugins: [
    //     '@vueform/slider/tailwind'
    // ],
    theme: {
        extend: {
            colors: {
                'primary-300': withOpacityValue('--color-brand-300'),
                'primary-400': withOpacityValue('--color-brand-400'),
                'primary-500': withOpacityValue('--color-brand-500'),
                'primary-600': withOpacityValue('--color-brand-600'),
                'primary-700': withOpacityValue('--color-brand-700'),

                'primary-gray-100': withOpacityValue('--color-brand-gray-100'),
                'primary-gray-200': withOpacityValue('--color-brand-gray-200'),
                'primary-gray-300': withOpacityValue('--color-brand-gray-300'),
                'primary-gray-400': withOpacityValue('--color-brand-gray-400'),
                'primary-gray-500': withOpacityValue('--color-brand-gray-500'),
                'primary-gray-600': withOpacityValue('--color-brand-gray-600'),
                'primary-gray-700': withOpacityValue('--color-brand-gray-700'),
                'primary-gray-800': withOpacityValue('--color-brand-gray-800'),
                'primary-gray-900': withOpacityValue('--color-brand-gray-900'),

                'secondary': withOpacityValue('--color-secondary'),
            },
            spacing: {
                '18': '4.5rem',
                '256': '64rem',
                '288': '72rem',
                '1080p-w': '1920px',
                '1080p-h': '1080px',
            },
            width: {
                'screen-h': '100vh',
                '128': '32rem',
            },
            minWidth: {
                '12': '3rem',
            },
            fontFamily: {
                'sans': ['Mukta', 'Helvetica', 'Arial', 'sans-serif'],
                'mono': ['Roboto Mono', 'monospace']
            },
            fontSize: {
                '3xl': '2rem',
                '6xl': '3.4rem',
            },
            transitionDuration: {
                2000: "2000ms",
            },
            ringWidth: {
                '12': '12px',
            },
            boxShadow: {
                'inset-white': 'inset 0 0 15px 15px rgba(255, 255, 255, 1)',
            },
            dropShadow: {
                'lg': [
                    '0 10px 8px rgb(0 0 0 / 0.01)',
                    '0 4px 3px rgb(0 0 0 / 0.03)'
                ],
                '3xl': '0 15px 50px rgba(0, 0, 0, 0.15)',
                'left': '-15px 0 15px rgba(0, 0, 0, 0.08)',
            },
            strokeWidth: {
                '3': '3px',
            },
            animation: {
                'pulsate': 'pulsate 8s infinite linear',
                'loader': 'loader 0.6s infinite alternate',
                'progress': 'progress 0.6s linear forwards',
                'fadeIn': 'toastFadeIn 0.5s ease-in-out',
                'fadeOut': 'toastFadeIn  0.5s ease-in-out reverse',
            },
            keyframes: {
                pulsate: {
                    '0%': { transform: 'scale(0.25)',  opacity: '0' },
                    '30%': { transform: 'scale(0.475)',  opacity: '0.5' },
                    '100%': { transform: 'scale(1)',  opacity: '0' },
                },
                loader: {
                    to: {
                        opacity: 0.1,
                        transform: 'translate3d(0, -1rem, 0)'
                    }
                },
                progress: {
                    to: {
                        width: 0
                    }
                },
                toastFadeIn: {
                    from: { opacity: 0, transform: 'scale(0.4)' },
                    to: { opacity: 1, transform: 'scale(1)' }
                }
            },
            screens: {
                '3xl': '1800px',
            },
            backgroundImage: (theme) => ({
                'rad-white-lt': 'radial-gradient(circle at 1.4e-14% 0.96%, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.00) 93%)',
                'multiselect-caret': `url("${svgToDataUri(
                `<svg viewBox="0 0 320 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>`,
                )}")`,
                'multiselect-spinner': `url("${svgToDataUri(
                `<svg viewBox="0 0 512 512" fill="${theme('colors.green.500')}" xmlns="http://www.w3.org/2000/svg"><path d="M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z"></path></svg>`,
                )}")`,
                'multiselect-remove': `url("${svgToDataUri(
                `<svg viewBox="0 0 320 512" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>`,
                )}")`,
            })
        },
    },
};
