<script setup lang="ts">
import { computed } from 'vue';
import * as solid from '@heroicons/vue/24/solid';
import * as outline from '@heroicons/vue/24/outline';
import * as mini from '@heroicons/vue/16/solid';

const props = defineProps<{
    icon: string;
    outline?: boolean;
    mini?: boolean;
}>();

const icons = computed(() => props.mini ? mini : (props.outline ? outline : solid));
const heroIcon = icons.value[props.icon + 'Icon'];
</script>

<template>
    <component v-if="heroIcon" :is="heroIcon" class="[&>path]:stroke-[1.5]"/>
</template>
