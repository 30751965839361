import { USD, EUR } from '@dinero.js/currencies';
import { dinero, toDecimal, multiply, subtract, toSnapshot } from 'dinero.js';

class Price {

    public price: number;
    public currency: string;

    constructor(price: number, currency: string) {
        this.price = price;
        this.currency = currency;
    }

    private getCurrencyType() {
        if(this.currency == 'EUR') {
            return EUR;
        } else if(this.currency == 'USD') {
            return USD;
        }
    }

    private getCurrencyLocale() {
        if(this.currency == 'EUR') {
            return 'nl-BE';
        } else if(this.currency == 'USD') {
            return 'en-US';
        }
    }

    public total() {
        return dinero({
            amount: this.price,
            currency: this.getCurrencyType(),
        });
    }

    public formattedPrice() {

        const locale = this.getCurrencyLocale();

        return this.intlFormat(this.total(), locale);
    }

    public withoutVat(vatAmount) {
        return new Price(Math.round(this.price/(1+(vatAmount/100))), this.currency);
    }

    public vatTotal(vatAmount) {
        return new Price(
            toSnapshot(subtract(this.total(), this.withoutVat(vatAmount).total())).amount,
            this.currency,
        );
    }

    public subtractValue(value) {
        const locale = this.getCurrencyLocale();
        const multiplied = multiply(this.total(), { amount: value, scale: 2 });
        return this.intlFormat(subtract(this.total(), multiplied), locale);
    }

    intlFormat(d, locale, options = {}) {

        return toDecimal(d, ({ value, currency }) => {
            return Number(value).toLocaleString(locale, {
                ...options,
                style: 'currency',
                currency: currency.code,
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
            });
        });
    };
}


export default Price;
