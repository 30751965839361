import * as PIXI from 'pixi.js';
import { Wall } from "@/utils/classes/Wall";
import type { ConfigurationMarker } from '@/types/configurationmarkers.types';
import { getMaxValues } from '../pixiFloorplan/Room';
import type { ProductVariant } from '@/types/productvariant.types';

export default class DrawFloor {
	public floorGraphic: PIXI.Graphics;
	private walls: Wall[];
    private configurationMarker: ConfigurationMarker;
    private product_variant: ProductVariant;
    private container: PIXI.Container;
    private color: number;
    private borderWidth: number;
    private floorMaskGraphics: PIXI.Graphics;
    private tileContainer: PIXI.Container;
    private roomDimensions: { width: number, height: number};

	constructor(
        walls: Wall[],
        configurationMarker: ConfigurationMarker,
		product_variant: ProductVariant,
        container: PIXI.Container,
        color: number,
        borderWidth: number = 10 // Set a default border width if not provided
    ) {
        this.walls = walls;
        this.configurationMarker = configurationMarker;
        this.product_variant = product_variant;
        this.container = container;
        this.color = color;
        this.borderWidth = borderWidth || 10; // Set a default border width if not provided
		this.drawFloor();
    }

	public drawFloor() {
		const graphic = new PIXI.Graphics();

		// Set the line style to draw a border
		graphic.lineStyle(this.borderWidth, this.color);

		graphic.beginFill(this.color, 0.01);

		graphic.moveTo(this.walls[0].from.x, this.walls[0].from.y);

		this.walls.forEach((wall) => {
			if (!wall.free) {
				graphic.lineTo(wall.to.x, wall.to.y);
			}
		});

		graphic.closePath();
		graphic.endFill();
		graphic.zIndex = 10;
		graphic.alpha = 0;
		graphic.xid = this.configurationMarker.xid;

		const max_wall_sizes = getMaxValues(this.walls);

		this.roomDimensions = {
			width: max_wall_sizes.x,
			height: max_wall_sizes.y
		}

		//Create a new TilingSprite with the tileable image texture
		//When things would slow down check if using rectangle mask would speed things up
		this.floorMaskGraphics = graphic.clone();
		this.floorMaskGraphics.eventMode = 'none';
		//Create a container to hold all the randomly rotated and flipped tiles
		this.tileContainer = new PIXI.Container();
		this.tileContainer.eventMode = 'none';
		//Add the variant image to the floor
		// this.addFloorTexture();
		//Add a white background instead
		const floorBackground = new PIXI.Graphics();
		floorBackground.beginFill(0xffffff);
		floorBackground.drawRect(0, 0, this.roomDimensions.width, this.roomDimensions.height);

		this.tileContainer.addChild(floorBackground);

		//Mask the tiles to the floor
		this.tileContainer.mask = this.floorMaskGraphics;

		this.container.addChild(this.tileContainer);
		this.container.addChild(this.floorMaskGraphics);

		this.container.addChild(graphic);

		this.floorGraphic = graphic;
		this.floorGraphic.eventMode = 'none';
	}

	public updateVariant(product_variant: ProductVariant) {
        this.product_variant = product_variant;
        // Clear existing tiles
        this.tileContainer.removeChildren();
        // Add new tiles with updated variant
        this.addFloorTexture();
    }

	private addFloorTexture(){
		if(PIXI.Assets.cache.has(this.product_variant.image)){
			this.createFloorTexture(PIXI.Assets.cache.get(this.product_variant.image));
		}else{
			PIXI.Assets.load(this.product_variant.image).then((texture) => {
				this.createFloorTexture(texture);
			});
		}
	}

	private createFloorTexture(texture: PIXI.Texture){
		const tileWidth = this.product_variant.length;
		const tileHeight = this.product_variant.width;
		const isSquare = tileWidth === tileHeight;
		const useStagger = !isSquare || this.product_variant.stagger;

		const scaleX = tileWidth / texture.width;
		const scaleY = tileHeight / texture.height;
		const scale = Math.max(scaleX, scaleY);

		// Calculate the number of tiles needed to cover the room
		const numTilesX = Math.ceil(this.roomDimensions.width / tileWidth) + 1;
		const numTilesY = Math.ceil(this.roomDimensions.height / tileHeight) + 1;

		// Initialize stagger factor array
		const staggerFactors = [];

		//Loop to create and position each tile
		for (let y = 0; y < numTilesY; y++) {
			// Calculate stagger factor for this row if it's not already calculated
			staggerFactors[y] = this.product_variant.stagger ? this.product_variant.stagger : 0.35 + Math.random() * 0.3; // Random value between 0.35 and 0.65

			for (let x = 0; x < numTilesX; x++) {
				// Create sprite with the texture
				const tileSprite = new PIXI.Sprite(texture);

				// Set the anchor point to the center of the sprite
				tileSprite.anchor.set(0.5)

				// Calculate position with staggering
				// const posX = x * tileWidth + (y % 2 === 1 ? tileWidth / 2 : 0);
				const posX = x * tileWidth + (useStagger ? (y % 2 === 1 ? -tileWidth * staggerFactors[y] : 0) : 0);
				const posY = y * tileHeight;

				// Position the sprite based on the current loop iteration
				tileSprite.position.set(posX, posY);

				// Apply random rotation based on tile dimensions
				if (tileWidth === tileHeight) {
					// Square tile, rotate randomly by 90 degrees
					const rotationIndex = Math.floor(Math.random() * 4); // Random index from 0 to 3
					tileSprite.rotation = rotationIndex * (Math.PI / 2); // Convert index to rotation angle in radians
				} else {
					// Non-square tile, randomize between 0 and 180 degrees
					tileSprite.rotation = Math.random() < 0.5 ? 0 : Math.PI;
				}

				tileSprite.scale.set(scale, scale);
				// Add the tile sprite to the container
				this.tileContainer.addChild(tileSprite);
			}
		}
	}
}
