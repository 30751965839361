import { Configuration } from "@/types/configuration.types";
import { defineStore } from "pinia";
import axios from 'axios';
import { useGeneralStore } from "./general";
import { useMarkerStore } from "./marker";
import { useRoomStore } from "./rooms";

export const useConfigurationStore = defineStore('configuration', {
    state: () => {
        return {
            configuration: null as Configuration | null,
        }
    },
    actions: {
        $reset () {
            this.configuration = null;
        },
        setConfiguration(configuration: Configuration) {
            this.configuration = configuration;
        },
        async syncConfigurationChanges() {

            const generalStore = useGeneralStore();
            const markerStore = useMarkerStore();

            try {

                const { floorplan_data, room_height, square_meters } = useRoomStore().room
                await axios.patch(route('client.configurations.patch', {
                    configuration: this.configuration.id,
                }), {
                    room: { floorplan_data, room_height, square_meters },
                    room_type: 'bathroom',
                    vat: generalStore.vatAmount,
                    configurationMarkers: markerStore.configurationMarkers,
                });
            } catch (error) {
                this.createNotification({
                    type: 'error',
                    message: error.message,
                });

                throw error;
            }
        }
    }
});
