import Price from '@/etc/price';
import { useGeneralStore, useRoomStore } from '@/stores';
import { ComplementaryModel } from '@/types/complementarymodel.types';
import { PositioningRule } from '@/types/positioningrule.types';
import { ProductVariant as ProductVariantType } from '@/types/productvariant.types';
import _ from 'lodash-es';
import { computed } from 'vue';
import { ConfigurationMarker } from './ConfigurationMarker';
import { DrawingType } from '@/types/drawinglogic.types';
import { Zone } from '@/types/configurationmarkers.types';
import * as visualizerAction from "@/utils/visualizer";

export class ProductVariant implements ProductVariantType {

    id: number;
    category_id: number;
    color_code: string;
    complementaryModels: ComplementaryModel[];
    fixed_wallcover_height: boolean;
    grout_color: string;
    grout_width: number;
    tile_bevel: number;
    adjustable: boolean;
    height_offset: number;
    image: string;
    height: number;
    length: number;
    width: number;
    master: boolean;
    name_extension: string;
    pack_surface: number;
    stagger: number;
    positioning_rule_id: number;
    positioning_rules: PositioningRule[];
    price: Object;
    price_inc_new: Object;
    price_inc_renovation: Object;
    product_group_id: number;
    product_id: number;
    product_name: string;
    quantity: number;
    calculated_price: number;
    sku: string;
    model_reference: string;
    triggered_actions: any[];
    use_surface_pricing: boolean;

    constructor(properties: ProductVariantType) {
        for (let key in properties) {
            if (properties.hasOwnProperty(key)) {
                this[key] = properties[key];
            }
        }
    }

    get basePrice (): Price {
        return computed(() => {
            const priceProperty = useGeneralStore().active_vat_setting === 'inc_renovation'
            ? 'price_inc_renovation'
            : 'price_inc_new';

            return new Price(
                +this[priceProperty].amount,
                useGeneralStore().currency,
            );
        }) as any;
    }

    get calculatedPrice (): Price {
        return computed(() => {
            return new Price(
                this.basePrice.price * this.quantity,
                useGeneralStore().currency,
            );
        }) as any;
    }

    get originalCalculatedPrice (): Price {
        return computed(() => {
            return new Price(
                this.calculated_price,
                useGeneralStore().currency,
            );
        }) as any;
    }

    get selectedPositioningRule (): PositioningRule {
        return computed (() => {
            return this.positioning_rules?.find((rule) => {
                return rule.id == this.positioning_rule_id;
            });
        }) as any;
    }

    priceHasChanged (): boolean {
        return !_.isEqual(this.calculatedPrice, this.originalCalculatedPrice);
    }

    toJSON () {
        this.calculated_price = this.calculatedPrice.price;

        return this;
    }

    setNextPositioningRule(context: ConfigurationMarker) {

        const validRules = this.getPositioningRulesForContext(context);

        // Get current position of the active rule
        let currentIndex = validRules.findIndex(rule => rule.id === this.positioning_rule_id);
        currentIndex = currentIndex < 0 ? 0 : currentIndex;

        // Increment the index to switch to the next positioning rule in the list
        const newIndex = (currentIndex + 1) % validRules.length;

        // Set the new positioning rule ID for the variant using the new index
        this.positioning_rule_id = validRules[newIndex].id;

        this.updateQuantity(context);

        if (this.positioning_rule_id) {
            //Send the variant to the visualizer with new positioning
            visualizerAction.sendToVisualizer(context, this);
        } else {
            visualizerAction.deleteObjectModular(
                context.xid,
                this,
            );
        }
    }

    setSimilarPositioningRule(context: ConfigurationMarker, compareAgainst: PositioningRule) {
        this.positioning_rule_id = this.getSimilarPositioningRule(context, compareAgainst)?.id;
    }

    // Retrieve a positioning rule that is similar to a given rule, and is
    // valid in the given module context.
    getSimilarPositioningRule(context: ConfigurationMarker, compareAgainst: PositioningRule) {
        const propertiesToCompare = [
            'bounding_box_x_position',
            'bounding_box_y_position',
            'bounding_box_z_position',
            'dummy',
            'offset_x',
            'offset_y',
            'offset_z',
            'rotation',
            'object_multiplier',
        ];

        const validRules = this.getPositioningRulesForContext(context);
        let positioningRule = null;

        if(!!compareAgainst){
            positioningRule = validRules
                .find(rule => {
                    return propertiesToCompare.every(prop => rule[prop] === compareAgainst[prop])
                });
        }

        if (!positioningRule) {
            // Set the positioning rule to the first available positioning rule for the selected variant.
            positioningRule = validRules[0];
        }

        return positioningRule;
    }

    // Retrieve this variant's positioning rules that are valid in the
    // context of a given module.
    getPositioningRulesForContext(context: ConfigurationMarker)
    {
        let positioningRules = _.orderBy(this.positioning_rules, ['priority']);

        return positioningRules.filter(rule => {
            if (!rule) {
                return false;
            }

            return !rule.product_group_id || context.selectedProductGroups.map(group => group.id).includes(rule.product_group_id);
        });
    }

    updateQuantity(context: ConfigurationMarker) {
        if (this.pack_surface) {
            this.quantity = this.calculatePackageQuantity(context);
        } else {
            this.quantity = this.selectedPositioningRule?.object_multiplier || 1;
        }
    }

    calculatePackageQuantity(
        context: ConfigurationMarker,
    ) {
        const roomStore = useRoomStore();
        const drawing_logic = context.marker.drawing_logic.toLowerCase();

        if (this.pack_surface <= 0 || !Number.isFinite(this.pack_surface)) {
            return 0;
        }

        let squareMeters = 0;

        if (drawing_logic === DrawingType.FLOOR) {
            squareMeters = roomStore.square_meters_with_cutoff;
        } else if (drawing_logic === DrawingType.WALL) {
            squareMeters = roomStore.getWallSquareMetersWithCutoff(context.position_data.wall);
        }else{
            squareMeters = this.getZoneSquareMetersWithCutoff(context.zones);
        }

        return Math.ceil(squareMeters / this.pack_surface);
    };

    getZoneSquareMetersWithCutoff(zones: Zone[]){
        const generalStore = useGeneralStore();
        const cutoff = generalStore.cutoffPercentage;

        let totalSquareMetersZone = (zones || []).reduce((total, zone) => {
            const width = zone.to.x - zone.from.x;
            const height = zone.to.y - zone.from.y;
            const area = width * height;
            return total + area;
        }, 0) ?? 0;

        if (cutoff > 0) {
            totalSquareMetersZone *= cutoff;
        }

        return totalSquareMetersZone /= 10000;
    };
}
