import * as PIXI from 'pixi.js';
import { loadBitMapFont } from '@/fonts/LoadBitmapFont';

const DEFAULT_LINE_STYLE = {
	width: 10,
	color: 0x1f262a,
	alpha: 1,
	// cap: PIXI.LINE_CAP.SQUARE,
	// join: PIXI.LINE_JOIN.MITER,
	alignment: 1,
}

export default class DrawWalls {
	constructor(options) {
		this.walls = options.walls
		this.wallThickness = options.wallThickness
		this.container = options.container
		this.wallColor = options.wallColor

		return this.setupWalls();
	}
	setupWalls() {
		loadBitMapFont();

		let wallGraphics = new PIXI.Graphics();
		// wallGraphics.interactive = false;
		wallGraphics.eventMode = 'auto';
		wallGraphics.isWalls = true;
		wallGraphics.zIndex = 110;

		//Loop both room and free walls seperatly
		const freeWalls = Array.isArray(this.walls)
                  ? this.walls.filter(wall => wall.free === true)
                  : [this.walls].filter(wall => wall.free === true);

		const roomWalls = Array.isArray(this.walls)
                  ? this.walls.filter(wall => !wall.free || wall.free === false)
                  : [this.walls].filter(wall => !wall.free || wall.free === false);
		// const freeWalls = this.walls.filter(wall => wall.free === true);
		// const roomWalls = this.walls.filter(wall => !wall.free || wall.free === false);

		this.drawWalls(roomWalls, wallGraphics, false);
		this.drawWalls(freeWalls, wallGraphics, true);

		this.container.addChild(wallGraphics);

		return wallGraphics;
	}

	drawWalls(walls, wallGraphics, freeWalls = false){
		let alignment = 1;

		walls.forEach((wall) => {
			//When you draw free walls, no need to create outer walls
			if(!freeWalls){
				let firstWall = walls.indexOf(wall) === 0;
				wallGraphics = drawWall(wallGraphics, wall, firstWall, 100, { width: this.wallThickness, color: this.wallColor, alignment: alignment});
			}else{
				alignment = 0.5;
			}

			// Create a sepperated clickable innerWall on top of the walls
			const interactionGraphics = new PIXI.Graphics();
			interactionGraphics.eventMode = 'static';

			interactionGraphics.beginFill(this.wallColor); // Choose a color for interaction
            interactionGraphics.drawRect(-wall.getLength() / 2, -this.wallThickness, wall.getLength(), this.wallThickness);
            interactionGraphics.endFill();

			const wallCenter = wall.getCenter();

			interactionGraphics.x = wallCenter.x;
			interactionGraphics.y = wallCenter.y;
			interactionGraphics.angle = wall.getAngle();

			interactionGraphics.wall_id = wall.id;
			interactionGraphics.free = freeWalls;

			//Init the wall measurement
			setWallMeasurements(this.container, wall);

			wallGraphics.addChild(interactionGraphics);
		});

		if(!freeWalls){
			wallGraphics.closePath();
		}
	}
}


export function setWallMeasurements(container, wall){
		const wallLength = parseFloat((wall.getLength() / 100).toFixed(2));

		const rectHeight = 34;
		const rectWidth = 44;

		const sizeBG = new PIXI.Graphics();
		sizeBG.beginFill(0xf0f0f0);
		sizeBG.lineStyle(2, 0xffffff);
		sizeBG.drawRoundedRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight, 14);
		sizeBG.endFill();

		const textItem = new PIXI.BitmapText(wallLength + " m", {
			fontName: "Mukta",
			fontSize: 8,
			tint: 0x000000
		});
		textItem.anchor.set(0.5, 0.8);
		textItem.name = "measurementText"; // Set the name of the child

		sizeBG.addChild(textItem);
		sizeBG.visible = false;
		sizeBG.zIndex = 120;
		sizeBG.wall_id = wall.id;

		container.addChild(sizeBG);
}

export function drawWall(graphic = new PIXI.Graphics(), wall, newWall = false, zIndex, lineConfig = null){
	//When standalone or free wall,
	if(newWall){
		graphic.lineStyle( { ...DEFAULT_LINE_STYLE, ...(lineConfig || {}) });
		graphic.moveTo(wall.from.x, wall.from.y);
	}

	graphic.lineTo(wall.to.x, wall.to.y);
	graphic.zIndex = zIndex;

	return graphic;
}
